const textsArray = [
    "AI will shape the future",
    "The future belongs to AI",
    "AI is paving the way for the future",
    "The future is driven by AI",
    "In the future, AI will play a crucial role",
    "AI holds the key to the future",
    "The future is synonymous with AI",
    "AI is the driving force behind the future",
    "Future advancements will be led by AI",
    "AI is at the forefront of shaping tomorrow",
    "The future landscape will be shaped by AI",
    "AI is the future catalyst for change",
    "Tomorrow's progress is guided by AI",
    "AI is the cornerstone of future innovations",
    "The future is an AI-driven era",
    "AI is the beacon illuminating the future",
    "Future breakthroughs are intertwined with AI",
    "The future narrative is scripted by AI",
    "AI is the engine propelling us into the future",
    "In the tapestry of the future, AI is the thread",
    // Repeat the above variations to reach a total of 200
    // ...
];


const repeatedTextsArray = Array.from({ length: 200 }, (_, index) => textsArray[index % textsArray.length]);

export default repeatedTextsArray