import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './Components/Header/header';
import Footer from './Components/Footer/footer';
import ProductCard from './Components/Card/card';
import Homepage from './Pages/Homepage/homepage';
import PreLoader from './Components/PreLoader/preLoader';
import urlFetch from './Helpers/urlFetcher';
import router from './Helpers/router';

export const header = ReactDOM.createRoot(document.getElementById('header'));
export const root = ReactDOM.createRoot(document.getElementById('root'));
export const footer = ReactDOM.createRoot(document.getElementById('footer'));


header.render(
  <Header/>
);

root.render(
  <PreLoader/>
);

urlFetch("full").then((e)=>{
  router(e[0], e[1])
})
footer.render(
  <Footer/>
);
