const SearchBar = () => {


    return(
        <>
        <form className="flex items-center w-full my-10 md:my-28 md:w-5/6 mx-auto">
  <label htmlFor="s-search" className="sr-only ">
    Search
  </label>
  <div className="relative w-full">
    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
      <svg
        className="w-5 h-5 text-gray-500 dark:text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clipRule="evenodd"
        />
      </svg>
    </div>
    <input
    id="s-search"
      type="text"
      className="bg-[#00000000] dark:bg-[#00000000] border border-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-4 "
      placeholder="Search Models..."
      required=""
    />
   
  </div>
  <button
    type="submit"
    className="gap-2 inline-flex items-center py-4 px-3 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
  >
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 15.5C6.75 15.0858 6.41421 14.75 6 14.75H1C0.58579 14.75 0.25 15.0858 0.25 15.5C0.25 15.9142 0.58579 16.25 1 16.25H6C6.41421 16.25 6.75 15.9142 6.75 15.5Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.25 4.5C15.25 4.08579 15.5858 3.75 16 3.75H21C21.4142 3.75 21.75 4.08579 21.75 4.5C21.75 4.91421 21.4142 5.25 21 5.25H16C15.5858 5.25 15.25 4.91421 15.25 4.5Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.75 15.5C21.75 15.0858 21.4142 14.75 21 14.75H12C11.5858 14.75 11.25 15.0858 11.25 15.5C11.25 15.9142 11.5858 16.25 12 16.25H21C21.4142 16.25 21.75 15.9142 21.75 15.5Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4.5C0.25 4.08579 0.58579 3.75 1 3.75H10C10.4142 3.75 10.75 4.08579 10.75 4.5C10.75 4.91421 10.4142 5.25 10 5.25H1C0.58579 5.25 0.25 4.91421 0.25 4.5Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 13.5C5.25 12.5335 6.0335 11.75 7 11.75H11C11.9665 11.75 12.75 12.5335 12.75 13.5V17.5C12.75 18.4665 11.9665 19.25 11 19.25H7C6.0335 19.25 5.25 18.4665 5.25 17.5V13.5Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 2.5C16.75 1.5335 15.9665 0.75 15 0.75H11C10.0335 0.75 9.25 1.5335 9.25 2.5V6.5C9.25 7.4665 10.0335 8.25 11 8.25H15C15.9665 8.25 16.75 7.4665 16.75 6.5V2.5Z" fill="white"/>
</svg>
    Filters
  </button>
</form>

        </>
    )
}

export default SearchBar