const urlFetch = (arg) => {

    return new Promise ((resolve, reject)=>{

        //Fetching URL Start
        const url = window.location.pathname.split("/")
        url.splice(0,1)
        //Fetching URL Done, URL is now in an array called url

        switch(arg){
            case 'top':
                resolve(url[0])
            break;
            case "sub":
                resolve(url[1])
            break;
            case "full":
                resolve(url)
            break;
            default:
                reject("No valid arguments provided")
            break;
        }

    })

}

export default urlFetch