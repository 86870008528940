import './style.css'

const PreLoader = () => {
    return(
        <div className='justify-center items-enter  h-60vh'>
       <center className='mt-10 py-auto'> 
       <h2 className='ph2'>AI AppStore</h2>
            </center>
        </div>

    )
}

export default PreLoader