import { useEffect, useState } from "react"
import { ghd } from "../../Helpers/firebase"
import { root } from "../../index"
import { AppNotFound } from "../../Components/Not Found/notFound"
import rating from "./rating.png"
import { Tabs } from "antd"

const SingleAppPage = () => {

    const [data, setData] = useState({
        AppURL: "",  Image: "",  Introduction: "",  Name: "",  VideoDemo: "",  id: "", about: {main:[], safety:[]}, attachments: ""
    })
    
    const [rtx, setRt] = useState(0)
    const [dom, setDom] = useState(<></>)
    
    useEffect(e=>{
      if(rtx === 0){
                const url = window.location.pathname.split("/")
                url.splice(0,1)
                ghd("sd", 1, "", `apps/${url[1]}`, 0).then((e)=>{
                    setData(e)    
                    root.render(<>
                        <div className="w-full md:w-2/3 mx-auto p-7">
                           <div className="block md:flex">
                           <img src={e.Image} className="w-1/5 h-1/6  mx-1 mb-4 md:mb-0 md:mx-8" alt={e.Name}/>
                           <div>
                           <h1 className="text-3xl text-white">{e.Name}</h1>
                           <h1 className="text-bse mt-2 text-white">{e.Introduction}</h1>
                           <img  src={rating} className="mt-4"/>
                           <div className=" mt-10 flex gap-4">
                 <a className='md:px-6 px-2 bg-[#263BDC] py-3 md:py-3 align-middle font-semibold rounded'>Demo Available</a> 
                 <a className='lia block px-4 py-2 md:py-3 align-middle font-semibold'>Try it for yourself</a>
                           </div>
                           </div>
                           
                           </div>

                            <div className="mt-24">
                            <Tabs style={{color:"white", border:"none"}} value="large" defaultActiveKey="1" items={new Array(3).fill(null).map((_, i) => {
          const id = String(i + 1);
          return {
            label: `${id === "1" ? "About" : id === "2" ? "Demo" : "Doumentation" }`,
            key: id,
            children:id === "1" ? <><h2 className="text-xl my-4">About This App</h2>{e.about.main.map((e, k)=>{return(<p className="text-base my-2">{e}</p>)})}<h2 className="text-xl my-8">Data Safety</h2><ul className="mt-4 list-disc ml-5">{e.about.safety.map((e, k)=>{return(<li className="text-base my-2">{e}</li>)})}</ul></>: id === "2" ? <><center><div className="w-4/5 mt-20 mx-auto"><iframe width="650" height="375" src={`https://www.youtube.com/embed/${e.VideoDemo.replace(/^https:\/\/www\.youtube\.com\/watch\?v=/, '')}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div></center></> : "Doumentation" ,
          };
        })} />
                            </div>



                        </div>
                    </>)
                    setRt(1)
    
                }).catch((e)=>{
                    console.log(e)
                    root.render(<AppNotFound/>)
                })
        }

    })

    return(<>
       <div className="text-white">
       {dom}
       </div>
    </>)
}

export default SingleAppPage