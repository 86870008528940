import { root } from ".."
import SingleAppPage from "../Pages/Apps/appPage"
import ContactPage from "../Pages/Contact/contact"
import Homepage from "../Pages/Homepage/homepage"

const router = (top, sub) => {

    switch(top.toLowerCase()){

//   Dynamic Routes Start
        case 'app':
            switch(sub.toLowerCase()){
                default:
                    root.render(<SingleAppPage/>)
                break
            }
        break
//   Dynamic Routes End

        case '':
            root.render(<Homepage/>)
        break
        case 'home':
            root.render(<Homepage/>)
        break
        case 'contact':
            root.render(<ContactPage/>)
        break
        default:

        break
    }

}






export default router