import telegram from './img/telegram.png';
import rating from "./img/rating.png";
import "./style.css"
import switcher from '../../Helpers/switcher';
const ProductCard = (ig, pn, ds, li) => {

return(
  <article>
  <div className="icon-part">
    <div className="icon">
      <img src={ig} alt={pn} />
    </div>
    <div className="rating-and-title">
      <h3 className="title">{pn}</h3>
      <img src={rating} alt="rating" />
    </div>
  </div>
  <div className="text">
    <p className="desc">
  {ds}
    </p>
    <a
    onClick={(e)=>{
      e.preventDefault()
      switcher(`../App/${li}`, 'App', li)
    }}
      className="md:px-3 px-2 bg-[#263BDC] py-2 md:py-2 align-middle font-semibold rounded text-white"
    >
      Try it out
    </a>
  </div>
</article>
)
}

export default ProductCard