import { useEffect, useState } from 'react';
import logo from '../../logo.svg'
import menu from '../../Media/menu.svg'
import menuclose from '../../Media/menuclose.svg'
import {Divider, Drawer} from "antd"
import HeaderAuthCheck from './authCheck';
import './style.css'
import switcher from '../../Helpers/switcher';
const Header = () => {

    const [open, setOpen] = useState(false);
    const [sr, setSr] = useState(0);
    const [er, setEr] = useState(0);
    const [rr, setRr] = useState(0);
    const [uS, setUS] = useState({dt:"Get Started", st:"Sign In"});

    useEffect(e => {
      if(rr === 0){
        HeaderAuthCheck().then((e)=>{
          if(sr === 0){
            setSr(1)
            setUS({dt:"Dashboard", st:`${e}`})
          }
        }).catch((e)=>{
          if(er === 0){
            setUS({dt:"Get Started", st:"Sign In"})
            setEr(1)
          }
        })
        setRr(1)
      }
    })

    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };


    return(<>
    <div style={{borderBottom: "0.1px solid #ffffff20"}}>
      
        <div className="w-full md:w-2/3 p-7 justify-center  flex mx-auto text-white md:text-large  ">
            <div className="w-2/3 mx-4 md:mx-4  md:w-1/5 py-3  md:py-3 mt-1 md:mt-0" ><a href='../../'><img src={logo}/></a></div>
            <div className="w-2/3  justify-center hidden md:flex">
                
                    <a onClick={()=>{switcher("/", "", "")}}  className="lia md:px-5 font-semibold md:py-3 ">Models</a>
                    {/* <a className="lia md:px-5 font-semibold md:py-3 ">Pricing</a> */}
                    {/* <a className="lia md:px-5 font-semibold md:py-3 ">Docs</a> */}
                    <a onClick={()=>{switcher("/contact", "contact", "")}}  className="lia md:px-5 font-semibold md:py-3 ">Contact</a>
               
            </div>
            <div className="w-full md:w-1/4 flex justify-center">
                
                {/* <a className='md:px-6 px-2 bg-[#263BDC] py-3 md:py-3 align-middle font-semibold rounded'>{uS.dt}</a> */}
                {/* <a className='lia hidden md:block px-4 py-2 md:py-3 align-middle font-semibold'>{uS.st}</a> */}
                <a className='md:hidden px-2  font-semibold py-2 rounded align-middle bg-[#343a40] rounded ml-24' onClick={showDrawer} ><img width={"32px"} src={menu}/></a>
              

            </div>
        </div>
    </div>
    <Drawer className='background' style={{backgroundColor:"#010327", color:"white"}} onClose={onClose} open={open} closeIcon={false} >
 <div className='flex'>
 <div>
    
 </div>
    <a className=' bg-[#343a40] rounded  px-4 py-2 align-middle font-semibold' onClick={()=>{onClose()}}><img width={"32px"} src={menuclose}/></a>
    {/* <a className='lia bg-[#263BDC] rounded mx-1 px-10 py-2 text-base align-middle font-semibold'>{uS.st}</a> */}
 </div>
    <div className="w-2/3">

                <Divider></Divider>
                <a className="px-3 py-3  my-2 block font-semibold">Menu</a>
                <a onClick={()=>{switcher("/", "", "")}}  className="lia px-3 py-3 border my-2 block font-semibold">Models</a>
                {/* <a className="lia px-3 py-3 border my-2 block font-semibold">Pricing</a> */}
                {/* <a className="lia px-3 py-3 border my-2 block font-semibold">Docs</a> */}
                <a onClick={()=>{switcher("/contact", "contact", "")}} className="lia px-3 py-3 border my-2 block font-semibold">Contact</a>
           
        </div>
        <br></br>
        <br></br>
        <Divider style={{color:"white"}}>© 2024 Intredia LLC.</Divider>

      </Drawer>
    </>)

}

export default Header