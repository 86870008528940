// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, where } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPZVAGS3gj3yoe25Dp-_r8BWO33Owbq-s",
  authDomain: "ousaimarketplace.firebaseapp.com",
  projectId: "ousaimarketplace",
  storageBucket: "ousaimarketplace.appspot.com",
  messagingSenderId: "911495036362",
  appId: "1:911495036362:web:bb254ac99d33be98696333",
  measurementId: "G-B3G48Z2RH2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth()
const db = getFirestore()


export const ghd = (dt, aN, Ci, Di, dq) =>{
  let q = {rq: [], "od": "", "ob": "", "wf": "", "wop": "", "wt": "",}
  if(dq !== 0){
    q = dq
  }
    return new Promise((rs, rj)=>{
      let aNu  = 999
      if(typeof aN === 'number'){
        aNu = Math.round(aN*10)/10;
      }else{
        aNu = 999;
      }
      switch(dt){
        case 'sd': 
        if(typeof(Di) ===  `string` ){
          getDoc(doc(db, `${Di}`)).then((e)=>{
            e.exists( ) ? rs({...e.data(), aN : aNu, _id: e.id }) : rj('Not Found');
          }).catch((e)=>{
            rj("Error Fetching Document, id: s90ss")
          })
        }else{
          rj('D Index was polluted with invalid data')
        }
        break;
        case 'dc':
          if(typeof(Ci) ===  `string` ){
            if(dq !== 0){
              getDocs(query(collection(db, `${Ci}`), orderBy(q["ob"], q["od"]), where(q["wf"], q["wop"], q["wt"]), limit(aN))).then((e)=> {
                rs(e.docs)
              }).catch((e)=>{
                rj("Error Fetching Document, id: s91ss")
              })
            }
            getDocs(query(collection(db, `${Ci}`),  limit(aN))).then((e)=>{
              rs(e.docs)
            }).then((e)=>{
              rj("Error Fetching Document, id: s92ss")
            })
          }else{
            rj('C Index was polluted with invalid data')
          }
        break;
        default :
          rj('no literate type was defined: e2q')
        break;
      }
    })
}


export const shd = (dt, Ci, Di, aT, dta) => {
  return new Promise((rs, rj)=>{

    cAh.then((e)=>{
          switch(dt){
            case 'sd': 
              switch(aT){
                case 'add':
                  addDoc(doc(db, `${Di}`), {...dta}).then((e)=>{
                    rs({'id': e.id})
                  }).catch((e)=>{
                    rj("Error Pushing Document, id: s90is")
                  })
                break;
                case 'set':
                  setDoc(doc(db, `${Di}`), {...dta}).then((e)=> {
                    rs()
                  }).catch((e)=>{
                    rj("Error Pushing Document, id: s98io")
                  })
                break;
                default:
                  rj('no literate type was defined: pj4')
                break;
              }
            break;
            default :
              rj('no literate type was defined: pi14')
            break;
      
          }
    }).catch((e)=>{
      rj("User is not authenticated")
    })
  })
}

export const cAh = () => {
    return new Promise((rs, rj)=>{
      onAuthStateChanged(auth, (e)=>{
        if(e){
          rs(e)
        }else{
          rj("No User")
        }
      })
    })
}
