import './style.css'

export const AppNotFound = () => {
    return(
        <div className='justify-center items-enter  h-60vh'>
       <center className='mt-10 py-auto'> 
       <h2 className='ph2'>Application Not Found</h2>
            </center>
        </div>
    )
}
