import { cAh } from "../../Helpers/firebase"

const HeaderAuthCheck = () => {
    return new Promise ((rs, rj)=>{
        cAh().then((e)=>{
                rs(e.displayName)            
        }).catch((e)=>{
            rj(0)
        })
    })
}

export default  HeaderAuthCheck;