const ContactPage = () => {


    return(
        <>
        <div className="mt-24 w-5/6 md:w-1/3 mx-auto rounded-lg border-2 border-[#263BDC] p-4 md:p-10 text-center ">
        <h2 className="text-2xl mt-2">Get In Touch With Us</h2>
        <h2 className="text-medium mt-2 mb-6">We are here to support you. Submit your question to us.</h2>
        
        <form className="text-start">
            <label>Name</label><br></br>
            <input typeof="text" className="bg-transparent border-2 rounded-md border-[#7C7C7C] w-full p-2 my-4"/>
            <label>Email</label><br></br>
            <input typeof="text" className="bg-transparent border-2 rounded-md border-[#7C7C7C] w-full p-2 my-4"/>
            <label>Name</label><br></br>
            <textarea typeof="text"  className="bg-transparent border-2 rounded-md border-[#7C7C7C] w-full p-2 my-4"/>
        </form>

        </div>
        </>
    )

}

export default ContactPage